a.button,
    button.styled {

        display: table;
        padding: 0;
        border: 0;
        background: transparent;
        text-decoration: none;

        &:not(:disabled){
            cursor: pointer;
        }

        span {

            display: inline-flex;
            align-items: center;
            gap: 12px;
            height: 48px;
            padding: 0 32px;
            color: #FFF;
            font-family: 'Archivo';
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            background-color: #E5007E;
            transition: all .3s ease-in-out;
        
        }

        &:hover span {
            background-color: #8C004C;
            color: #FFFFFF;
        }

        &.arrow-right span:after {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background: url( '../svg/arrow-button.svg' );
            transition: all .3s ease-in-out;
        }

        &.color {

            &--2 {

                span {
                    color: #333333;
                    background-color: #81CFF4;
                }

                &:hover span {
                    background-color: #004562;
                    color: #FFF;
                }
            
            }

            &--3 {

                span {

                    background-color: #FFEC00;
                    color: #333333;

                    &:after {
                        filter: brightness(0) invert(10%) sepia(52%) saturate(0%) hue-rotate(209deg) brightness(89%) contrast(75%);
                    }
                
                }

                &:hover span {

                    background-color: #004562;
                    color: #FFF;

                    &:after {
                        filter: brightness(0) invert(1);
                    }
                
                }
            
            }

        }

        &.inline {
        
            span {
                padding: 0 5px 0 0;
                text-decoration: underline;
                background-color: transparent;
            }

            &.color--4 {

                span {

                    color: #E5007E;

                    &:after {
                        filter: invert(38%) sepia(90%) saturate(7490%) hue-rotate(314deg) brightness(84%) contrast(118%);
                    }

                }

            }

            &:hover span {

                color: #8C004C;

                &:after {
                    filter: brightness(0) invert(10%) sepia(64%) saturate(7148%) hue-rotate(318deg) brightness(75%) contrast(103%);
                }
            
            }
        
        }

        &.small span {
            height: 40px;
            padding: 0 20px;
        }
        
    }