$timeTrans: 16.7s;
$timeDelay: .3s;
$timeIntro: 1.2s;

#carne-lnd div.hero {

	position: relative;
	width: 100%;
	height: 570px;
	position: relative;
	overflow: hidden;

	button {

		margin-top: 11px;
		animation: 
			heroButton $timeTrans linear infinite $timeDelay,
			heroButtonIntro $timeIntro linear
		;
		
		&,
		span {
			justify-content: center;
		}

		span {
			width: 274px;
		}

	}

	h2 {

		position: relative;
		text-align: left;
		color: #FFF;
		z-index: 5;

		strong {

			position: relative;
			opacity: 0;
			z-index: 5;

			&:nth-child(2),
			&:nth-child(3){
				position: absolute;
				top: 0;
				left: 0;
			}
			
			&:nth-child(1){
				animation: 
					texto1 $timeTrans linear infinite $timeDelay,
					texto1Intro $timeIntro linear
				;
			}
			&:nth-child(2){
				animation: texto2 $timeTrans linear infinite $timeDelay;
			}
			&:nth-child(3){
				animation: 
					texto3 $timeTrans linear infinite $timeDelay,
					texto3Intro $timeIntro linear
				;
			}
		
		}

		&:before {
			content: '';
			position: absolute;
			top: -53px;
			right: -48px;
			bottom: -45px;
			left: -60px;
			display: block;
			background: #E5007E;
			clip-path: polygon(0 34px, 100% 0%, calc( 100% - 12px ) 100%, 0% 100%);
		}
	
	}

	em {
		
		position: relative;
		display: block;
		font-family: 'Archivo';
		font-size: 56px;
		font-weight: 700;
		line-height: 56px;
		letter-spacing: -0.02em;
		text-align: left;
		z-index: 10;
		animation: 
			heroDesc $timeTrans linear infinite $timeDelay,
			heroDescIntro $timeIntro linear
		;

		mark {
			padding: 8px 5px 0;
			color: #333333;
			line-height: 73px;
			background-image: linear-gradient(90deg, rgba(255, 236, 0, 0) 50%, rgb(255, 236, 0) 0);
			clip-path: polygon(0 13px, 100% 0%, 100% 100%, 0% 100%);
	        font-style: normal;
	        background-color: transparent;
	        background-size: 200%;
	        background-position: -100%;
	        animation: heroDescMark $timeIntro linear;
		}

	}

	picture,
	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	.fundos,
	.floats {
		position: absolute;
		top: 50%;
		display: block;
		width: 100%;
		height: auto;
		transform: translateY(-50%);
	}

	.fundos {
		
		right: calc( 50% - 334px );
		width: 1294px;
		height: 457px;

		picture {
			&:nth-child(1){
				animation: 
					fundo1 $timeTrans linear infinite $timeDelay,
					fundo1Intro $timeIntro linear
				;
			}
			&:nth-child(2){
				animation: fundo2 $timeTrans linear infinite $timeDelay,
			}
			&:nth-child(3){
				animation:
					fundo3 $timeTrans linear infinite $timeDelay,
					fundo3Intro $timeIntro linear
				;
			}
		}

		img {
			object-fit: cover;
		}

	}
	
	.floats {

		right: calc( 50% - 22px );
		width: 495px;
		max-width: 495px;
		z-index: 10;

		&:after {
			content: '';
			display: block;
			position: relative;
			width: 100%;
			height: 0;
			padding-top: 116.1224489796%;
		}

		picture {
			&:nth-child(1){
				animation: 
					float1 $timeTrans linear infinite $timeDelay,
					float1Intro $timeIntro linear
				;
			}
			&:nth-child(2){
				animation: float2 $timeTrans linear infinite $timeDelay;
			}
			&:nth-child(3){
				animation: 
					float3 $timeTrans linear infinite $timeDelay,
					float3Intro $timeIntro linear
				;
			}
		}
		
		img {
			object-fit: contain;
		}
	
	}

}

@media( min-width: 581px ){

	#carne-lnd div.hero {
		
		.content {
			position: absolute;
			bottom: 32px;
			left: calc( 50% - 36px );
			padding-left: 60px;
			z-index: 5;
		}

	}

}

@media( max-width: 1130px ){

	#carne-lnd div.hero {

		.content {
			left: 420px;
		}
	
		.floats {
	  		right: initial;
	  		left: 0;
	  	}
	
	}

}

@media( max-width: 1020px ){

	#carne-lnd div.hero {

		height: 518px;

		.content {

			bottom: 86px;
			left: 372px;
		
			h2 {

				max-width: 412px;
				padding-right: 24px;
				font-size: 32px;
				line-height: 32px;

				br {
					display: none;
				}
			
			}

			em {

				line-height: 30px;

				mark {
					font-size: 32px;
					line-height: 32px;
					clip-path: polygon(0 10px, 100% 0%, 100% 100%, 0% 100%);
				}
		
			}

		}

		.floats {
  			width: 442px;
  		}

  		.fundos {
  			height: 372px;
  		}
	  				
	}

}

@media( max-width: 720px ){

 	#carne-lnd div.hero {

 		height: 382px;

		.floats {
			width: 300px;
		}
	
		.fundos {
			right: calc(50% - 253px);
			width: 618px;
			height: 300px;
		}

		.content {

			bottom: 18px;
	    	left: 233px;

	    	h2 {
	    		&:before {
	    			right: 30px;
	    		}
	    	}
		
		}

  	}
  	
}

@media( max-width: 630px ){
	#carne-lnd div.hero .content h2::before {
	    right: 0;
	}
}


@media( max-width: 580px ){
	
	#carne-lnd div.hero {

		height: auto;

		.content {

			max-width: 312px;
			margin-top: 58px;

			h2 {

				padding-right: 0;
				margin-bottom: 12px;
			
				&:before {
					top: -12px;
					right: -15px;
					bottom: -24px;
					left: -15px;
					clip-path: polygon(0 12px, 100% 0%, calc(100% - 12px) 100%, 0% 100%);
				}

			}

			em {
				margin-left: -5px;
			}

			button {
				width: 100%;
  				margin-top: 14px;
			}
		
		}

		.floats,
		.content {
			position: relative;
			top: initial;
			right: initial;
			left: initial;
			transform: none;
		}

		.fundos {
			top: 38px;
			right: 24px;
			height: 443px;
			width: calc( 100dvw - 48px );
			transform: none;
		}

		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	
	}

}
