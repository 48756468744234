#carne-lnd section#quem-somos {

	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1080px;
	padding-top: 52px;
	padding-bottom: 56px;

	> p {
		max-width: 842px;
		text-align: center;
		line-height: 27px;
	}

	.texts p {
		font-size: 18px;
		line-height: 27px;
	}

	div.sides {

		gap: 75px;
		padding: 0;
		margin-top: 32px;

		.image-wrapper {
  			margin-left: -44px;
		}
	
	}

	a.button {
		margin-top: 24px;
	}

}

@media( max-width: 980px ){

	#carne-lnd section#quem-somos div.sides {

		gap: 36px;

		.image-wrapper {
	  		margin: 0 auto;
	  	}

	}

}

@media( max-width: 640px ){

	#carne-lnd section#quem-somos {
		
		padding-top: 32px;

		h2 {
			font-size: 24px;
			line-height: 34px;
		}

		> p {
			text-align: left;
		}

	}

}
