#carne-lnd {

    padding-top: 80px;

    > header {

        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #E3E4E8;
        background: #fff;
        z-index: 99;

        > div {

            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1808px;
            height: 79px;
            padding: 0 24px;

        }

        h1 {
            margin: 0;
        }

        .floater {

            display: flex;

            #menu a {

                position: relative;
                text-decoration: none;

                strong {

                    position: relative;
                    display: table;
                    font-weight: normal;

                    &,
                    &:before {
                        white-space: nowrap;
                        transition: all .4s ease-in-out;
                    }

                    &:before {
                        content:attr(data-title);
                        display: table;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        color: #a20062;
                        font-weight: 600;
                        transform: translate(-50%,-50%);
                        opacity: 0;
                    }
                
                }

                &.active {

                    strong {

                        color: transparent;

                        &:before {
                            opacity: 1;
                        }
                    }

                }

            }
        
        }

    }

}

@media( min-width: 1123px ){

    #carne-lnd > header {

        border-bottom: 1px solid #E3E4E8;

        .floater {

            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            #menu {

                display: flex;
                gap: 14px;
                padding-left: 12px;
                margin-left: 24px;
                border-left: #E6E8EC 1px solid;

                a {

                    display: block;
                    padding: 0 12px;
                    color: #162639;

                    &,
                    &:before {
                        font-family: 'Inter';
                        line-height: 20px;
                        font-size: 14px;   
                    }

                }
            
            }

            .networks {
                display: none;
            }

        }

        .menu-toggle {
            display: none;
        }

    }

}

@media( max-width: 1228px ){
    #carne-lnd > header .floater #menu {
        gap: 0;
    }
}

@media( max-width: 1122px ){

    #carne-lnd > header {

        .floater {

            position: fixed;
            top: 80px;
            flex-direction: column;
            height: calc( 100dvh - 80px );
            background: #FFF;
            overflow-x: hidden;
            overflow-y: auto;
            transition: all .5s ease-in-out;

            > div {

                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 39px 24px 32px;
                background: #F9F9FC;
                border-top: 1px solid #E3E4E8;

                button.styled {

                    width: 100%;
                    
                    span {
                        width: 100%;
                        justify-content: center;
                    }
                
                }

                nav {

                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 100%;
                    border-top: #E3E4E8 1px solid;
                    padding-top: 16px;
                    margin-top: 16px;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 48px;
                        height: 48px;
                    }
                
                }
            
            }
        
        }

        #menu {

            flex: 1;
            display: flex;
            flex-direction: column;

            a {

                display: flex;
                justify-content: space-between;
                padding: 32px;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                color: #333333;
                text-decoration: none;

                &:after {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../svg/arrow-menu.svg') no-repeat center center;
                }

                &:not(:first-child){
                    border-top: 1px solid #E3E4E8;
                }

            }
        
        }

    }
    
}

@media( max-width: 1122px ){

    #carne-lnd > header {

        .floater {
            right: 0;
            width: 360px;
            transform: translateX(100%);
        }

        &:before {
            content: '';
            display: block;
            position: fixed;
            top: 80px;
            left: 0;
            width: 100dvw;
            height: calc( 100dvh - 80px );
            background: rgba( 0, 0, 0, 0.5 );
            opacity: 0;
            transform: translateX(-100%);
            transition:
                opacity .5s ease-in-out,
                transform 1ms ease .5s
            ;
        }

        &.menu-opened {
        
            .floater {
                transform: translateX(0);
            }
            
            &:before {
                transition: opacity .5s ease-in-out;
                opacity: 1;
                transform: translateX(0);
            }
        
        }

    }

}

@media( max-width: 480px ){

    #carne-lnd {

        padding-top: 72px;

        > header {

            .floater {
                top: 72px;
                width: 100dvw;
                height: calc( 100dvh - 72px );
            }

            > div {
                height: 71px;
            }

            .logo-wrapper svg {
                width: 110px;
                height: auto;
            }

        }

    }

}
