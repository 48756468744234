#carne-lnd > footer {

	a {
		text-decoration: none;
	}

	.menu-copy {
	
		position: relative;
		padding-top: 40px;
	    padding-bottom: 20px;
		background: #E5007E;

		.center {
			max-width: 1270px;
		}
	
	}

	.network-news {

		padding: 16px 0;

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		img {
			width: 138px;
			height: auto;
		}

		nav {

			display: inline-flex;
			align-items: center;
			gap: 15px;

			a {

				display: flex;
				align-items: center;
				justify-content: center;
				width: 36px;
				height: 36px;
				border-radius: 6px;
				border: 2px solid transparent;
				transition: all .4s;

				svg {
					width: 17px;
					height: auto;
				}

				&.facebook {
					border-color: #0062b2;
				}
				&.twitter {
					border-color: #000;
				}
				&.youtube {
					border-color: #ff1d25;
				}
				&.instagram {
					border-color: #E1306C;
				}

				&:hover {
					transform: scale(1.1);
				}
			
			}
		
		}
	
	}

	ul.inline {

		display: flex;
		margin-bottom: 33px;

		li {
			
			border-left: 2px solid white;

			img {
				margin-right: 7px;
			}

			a {
				display: inline-flex;
				align-items: center;
				padding: 0 14px 0 10px;
				font-family: 'Archivo';
			    font-weight: 700;
			    line-height: 32px;
			    font-size: 12px;
			    text-transform: uppercase;
			    color: #fff;
			}

		}
	
	}

	div.cols {
		
		display: flex;
		justify-content: space-between;

		> div {

			> a {
				font-family: 'Inter';
				font-weight: 700;
				line-height: 30px;
				letter-spacing: 0.5px;
				color: #FFEC00;
				text-transform: uppercase;
				white-space: nowrap;
			}

			ul {
				display: flex;
				flex-direction: column;
				margin: 13px 0 0;
			}

			li a {
				color: #FFF;
				font-family: 'Inter';
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.5px;
			}
		
		}

	}

	div.copy-licenses {

		display: flex;
		justify-content: space-between;
	    padding-top: 20px;
	    margin-top: 38px;
		border-top: 1px solid rgba( 252, 252, 252, 0.26 );
	    font-size: 14px;
		color: #FFF;
	    font-family: 'Inter';
	    line-height: 21px;
	    letter-spacing: 0.5px;

	}

	#aba_associe_se {

		position: fixed;
		right: 0;
		bottom: 42px;
		transform: translateX( calc( 100% - 46px ) );
		transition: all .4s ease-in-out;

		&:hover {
			transform: translateX( 1px );
		}
	
	}

}

@media( max-width: 1210px ){

	#carne-lnd > footer ul.inline {

		justify-content: space-between;

		li {
		
  			border-left: 0;

  			a {
				padding: 0;
  			}

			img {
		  		width: 14px;
		  		height: auto;
		  	}
	  	
	  	}
	
	}
	
}

@media (min-width: 851px ) AND ( max-width: 1000px ){


	#carne-lnd > footer ul.inline {
	
		li img {
			display: none;
		}
	
	}

}

@media( max-width: 1000px ){


	#carne-lnd > footer div.cols {
		
		> div {

			width: 25%;
			padding-right: 36px;

			> a {
				font-size: 16px;
			}
		
		}
	
	}

}

@media ( max-width: 850px ){


	#carne-lnd > footer {

		ul.inline {
		
			li {

				img {
					margin: 0;
					width: 26px;
				}

				strong {
					display: none;
				}
			
			}

		}
	
	}

}

@media( max-width: 680px ){
	
	#carne-lnd > footer {

		.network-news {
			
			padding: 28px 24px 25px;

			> div {

				flex-direction: column;
				gap: 46px;

				img {
					width: 193px;
				}

			}
		
		}

		.menu-copy {
			padding-top: 20px;
		}
	
		.network-news nav a {

			width: 48px;
			height: 48px;

			svg {
				width: 23px;
				height: auto;
			}
		
		}

		ul.inline {
			justify-content: center;
			align-items: center;
			gap: 8px;
			margin: 0;
		}

		div.cols {
			display: none;
		}

		div.copy-licenses {
			flex-direction: column;
			align-items: center;
			gap: 10px;
			margin-top: 20px;
		}
	
	}

}

@media( max-width: 682px ){
	#carne-lnd > footer #aba_associe_se {
		display: none;
	}
}

@media( max-width: 480px ){
	
	#carne-lnd > footer {

		.network-news button.styled {
			&,
			span {
				width: 100%;
			}
		}

		&.fix-button-news {

			padding-bottom: 81px;

			.button-news-wrapper {

				position: fixed;
				left: 0;
				bottom: 0;
				width: 100%;
				z-index: 9999;
				transform: translateY(100%);
				
				div {
					display: block;
					padding: 16px 24px;
					border-top: 1px solid rgba( 0, 0, 0, 0.10 );
					background: #EDDEE6;
					transition: transform .4s ease-in-out;
				}

				button.styled span {
					height: 48px;
					justify-content: center;
					font-size: 14px;
				}

			}

			&.show-button-news .button-news-wrapper div {
				transform: translateY(-100%);
			}

		}

	}

}
