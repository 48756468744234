#carne-lnd section.de-olho {

	padding-top: 56px;

	h3 {
		margin: 0;
		text-align: center;
		font-family: 'Archivo';
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
	}

	ul {

		display: flex;
		gap: 16px;
		flex-wrap: initial;
		margin-top: 32px;

		li {
			flex: 1 1 0px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 16px;
			padding: 51px 24px;
			border: 2.5px solid #E3E4E8;
			background: #FEFEFE;

			em {
				color: #6D7280;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
			}

		}

	}
	
}

@media( max-width: 640px ){
	
	#carne-lnd section.de-olho ul {

		flex-direction: column;
		gap: 32px;

		li {
			padding: 32px 23px;
		}
	
	}
	
}
