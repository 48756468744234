#carne-lnd section.biblioteca {

	padding-top: 80px;

	.slider-item {

		display: flex;
		flex-direction: column;
		width: 280px;
		padding: 24px 24px 14px;
		border: 1px solid #E3E4E8;
		background: #FEFEFE;
		height: initial !important;

		div {
			flex: 1;
			padding-bottom: 6px;
			border-bottom: 1px solid #F9F9FC;
		}

		h3 {
			margin: 0;
			color: #6D7280;
			font-family: 'Archivo';
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 27px;
		}

		em {
			margin-bottom: 8px;
			color: #6D7280;
			font-size: 12px;
			font-style: normal;
			line-height: 18px;
			letter-spacing: 0.48px;
			text-transform: uppercase;
		}
	
	}

}