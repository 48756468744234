// Titles
@keyframes texto1 {
  0% { opacity: 0; }
  2.3391812865% { opacity: 1; }
  31.5789473684% { opacity: 1; }
  33.3333333333% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes texto1Intro {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes texto2 {
  0% { opacity: 0; }
  33.3333333333% { opacity: 0; }
  35.6725146199% {opacity: 1; }
  64.9122807018% {opacity: 1; }
  66.6666666667% {opacity: 0; }
  100% { opacity: 0; }
}
@keyframes texto3 {
  0% { opacity: 1; }
  2.3391812865% { opacity: 0; }
  66.6666666667% { opacity: 0; }
  69.005847953216% { opacity: 1; }
  98.2456140351% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes texto3Intro {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

// Floats
@keyframes float1 {
  0% { opacity: 0; transform:scale(0.8) }
  2.3391812865% { opacity: 1; transform:scale(1) }
  31.5789473684% { opacity: 1; transform:scale(1) }
  33.3333333333% { opacity: 1; transform:scale(0.8) }
  35.6725146199% { opacity: 0; transform:scale(0.8) }
  100% { opacity: 0; transform:scale(0.8) }
}
@keyframes float1Intro {
  0% { opacity: 0; transform:scale(0.8) }
  100% { opacity: 1; transform:scale(1) }
}
@keyframes float2 {
  0% { opacity: 0; transform:scale(0.8) }
  33.3333333333% { opacity: 0; transform:scale(0.8) }
  35.6725146199% {opacity: 1; transform:scale(1) }
  64.9122807018% {opacity: 1; transform:scale(1) }
  66.6666666667% {opacity: 1; transform:scale(0.8) }
  69.005847953216% {opacity: 0; transform:scale(0.8) }
  100% { opacity: 0; transform:scale(0.8) }
}
@keyframes float3 {
  0% { opacity: 1; transform:scale(0.8) }
  2.3391812865% { opacity: 0; transform:scale(0.8) }
  66.6666666667% { opacity: 0; transform:scale(0.8) }
  69.005847953216% { opacity: 1; transform:scale(1) }
  98.2456140351% { opacity: 1; transform:scale(1) }
  100% { opacity: 1; transform:scale(0.8) }
}
@keyframes float3Intro {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

// fundos
@keyframes fundo1 {
  0% { opacity: 0; }
  2.3391812865% { opacity: 1; }
  33.3333333333% { opacity: 1; }
  35.6725146199% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes fundo1Intro {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fundo2 {
  0% { opacity: 0; }
  33.3333333333% { opacity: 0; }
  35.6725146199% {opacity: 1; }
  66.6666666667% {opacity: 1; }
  69.005847953216% {opacity: 0; }
  100% { opacity: 0; }
}
@keyframes fundo3 {
  0% { opacity: 1; }
  2.3391812865% { opacity: 0; }
  66.6666666667% { opacity: 0; }
  69.005847953216% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes fundo3Intro {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

// Subtitle
@keyframes heroDesc {
    0% { transform: translateY(10px) }
    2.3391812865% { transform: translateY(0) }
    31.5789473684% { transform: translateY(0) }
    33.3333333333% { transform: translateY(10px) }
    35.6725146199% { transform: translateY(0) }
    64.9122807018% { transform: translateY(0) }
    66.6666666667% { transform: translateY(10px) }
    69.005847953216% { transform: translateY(0) }
    98.2456140351% { transform: translateY(0) }
    100% { transform: translateY(10px) }
}
@keyframes heroDescIntro {
    0% { opacity: 0; transform: translateY(15px) }
    100% { opacity: 1; transform: translateY(0) }
}
@keyframes heroDescMark {
    0% { background-position: 0 }
    100% { background-position: -100% }
}

// Mark
@keyframes heroDesc {}
// background-position 1.6s,
// color 2s

// Button
@keyframes heroButton {
    0% { transform: translateY(-10px) }
    2.3391812865% { transform: translateY(0) }
    31.5789473684% { transform: translateY(0) }
    33.3333333333% { transform: translateY(-10px) }
    35.6725146199% { transform: translateY(0) }
    64.9122807018% { transform: translateY(0) }
    66.6666666667% { transform: translateY(-10px) }
    69.005847953216% { transform: translateY(0) }
    98.2456140351% { transform: translateY(0) }
    100% { transform: translateY(-10px) }
}
@keyframes heroButtonIntro {
    0% { opacity: 0; transform: translateY(-15px) }
    100% { opacity: 1; transform: translateY(0) }
}

// Interrogacoes
@keyframes questOne {
    0% { transform: scale(1) }
    16.6666666667% { transform: scale(1) }
    33.3333333333% { transform: scale(1.44) translateY(-10px) }
    50% { transform: scale(1.44) translateY(-10px) }
    66.6666666667% { transform: scale(1.28) }
    83.3333333333% { transform: scale(1.28) }
    100% { transform: scale(1) }
}
@keyframes questTwo {
    0% { transform: scale(1) }
    16.6666666667% { transform: scale(1) }
    33.3333333333% { transform: scale(1.30) translateX(10px) }
    50% { transform: scale(1.30) translateX(10px) }
    66.6666666667% { transform: scale(1.17) }
    83.3333333333% { transform: scale(1.17) }
    100% { transform: scale(1) }
}
@keyframes questTree {
    0% { transform: scale(1) }
    16.6666666667% { transform: scale(1) }
    33.3333333333% { transform: scale(0.48) }
    50% { transform: scale(0.48) }
    66.6666666667% { transform: scale(0.83) }
    83.3333333333% { transform: scale(0.83) }
    100% { transform: scale(1) }
}


