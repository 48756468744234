#carne-lnd section.como-questionar {

	padding: 56px 0;
	margin-top: 38px;
	background: #F9F9FC;

	.swiper-slide {
		height: initial !important;
	}

	.list-questions {

		margin-top: 32px;

		em {
			color: #6D7280;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}

		.slider-item {

			display: flex;
			align-items: center;
			gap: 24px;
			width: calc( 50% - 8px );
			padding: 32px 24px;

			> strong {
				color: #009EE2;
				font-family: 'Archivo';
				font-size: 96px;
				font-style: normal;
				font-weight: 700;
				line-height: 96px;
				letter-spacing: -1.92px;
			}

			&.warning {

				background: #004562;

				svg {
					width: 88px;
					min-width: 88px;
				}

				em {
					color: #FEFEFE;
					font-weight: 300;
				}
			
			}

		}

	}
	
}

@media( min-width: 769px ){

	#carne-lnd section.como-questionar .list-questions {

		> div {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			gap: 16px;
		}

		.slider-item {
			&:nth-child(2),
			&:nth-child(4){
				margin-top: 24px;
			}
		}

	}

}

@media ( max-width: 768px ){

	#carne-lnd section.como-questionar .list-questions .slider-item {
		flex-direction: column;
	}

}

@media (min-width: 481px){

	#carne-lnd section.como-questionar .list-questions .slider-item {
		border: 1px solid #E3E4E8;
		background: #FEFEFE;
		box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
	}

}

@media (min-width: 481px) AND ( max-width: 768px ){

	#carne-lnd section.como-questionar {

		.swiper {
			overflow: initial !important;
		}
	
		.list-questions .slider-item {
			width: 312px;
		}
	
	}

}

@media( max-width: 640px ){
	
	#carne-lnd section.como-questionar {
		padding-top: 30px;
	}

}

@media( max-width: 480px ){
	
	#carne-lnd section.como-questionar .list-questions {

		padding-bottom: 33px;
		border: 1px solid #E3E4E8;
		background: #FEFEFE;
		box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);

		.slider-dots {
			margin-top: 32px;
		}
	
	}

}
