#carne-lnd .modal-share {

	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100dvw;
	height: 100dvh;
	z-index: 99999;
	background: rgba(0,0,0,0.5);
	opacity: 0;
	transform: translateX(-100dvw);
	transition: 
		opacity .5s ease-in-out,
		transform 1ms ease .5s
	;

	> div {

		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 555px;
		padding: 32px;
		border-radius: 8px;
		border: 1px solid #E3E4E8;
		background: #FEFEFE;
		gap: 40px;

		> div > div {

			position: relative;
			display: flex;
			align-items: center;
			height: 66px;
			margin-top: 4px;
			border-radius: 5px;
			border: 1px solid #E3E4E8;
			background: #FEFEFE;

			.site-address {
				flex: 1;
				height: 66px;
				line-height: 66px;
				padding-left: 16px;
				border: 0;
				background: transparent;
				outline: none !important;
				color: #1E344F;
			}

			button {
				margin: 0 16px;
			}

			span {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 16px;
				border-radius: 5px;
				border: 1px solid #E3E4E8;
				background: #E3E4E8;
				opacity: 0;
				transform: translateX(-100dvw);
				transition: 
					opacity .4s ease-in-out,
					transform 1ms ease .4s
				;
			}

			&.copied span {
				transition: opacity .4s ease-in-out;
				opacity: 1;
				transform: translateX(0);
			}
		
		}
	
	}

	h2 {
		padding-right: 40px;
		margin: 0;
		font-family: 'Inter';
		color: #1E344F;
		font-size: 32px;
		line-height: 42px;
		font-weight: normal;
		text-align: left;
	}

	em {
		color: #162639;
		font-size: 16px;
		font-style: normal;
		line-height: 24px;
	}

	button {

		background: none;
		border: 0;
		padding: 0;
		cursor: pointer;

		&.close {
			position: absolute;
			top: 32px;
			right: 32px;
		}
	
	}

	.networks {
		justify-content: space-between;
		padding: 0;
	}

	&.opened {
		transition: opacity .5s ease-in-out;
		transform: translateX(0);
		opacity: 1;
	}

}

@media( max-width: 440px ){
	
	#carne-lnd .modal-share {

		> div {

			padding: 32px 26px;

			> div > div {
			
				padding: 8px 0;
			
				&,
				.site-address {
					height: auto;
				}

				.site-address {
					line-height: 34px;
					font-size: 16px;
				}
			
			}

	  	}

		.networks {
			padding: 0;
		}
	
	}

}
