#carne-lnd .slider-squares {

	margin-top: 32px;

	h3 {
		margin: 0 0 16px;
		text-align: center;
		font-family: 'Archivo';
		font-size: 20px;
		font-weight: 700;
		line-height: 20px;
	}

	em,
	cite {
		color: #6D7280;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}

	cite {
		margin-top: 9px;
	}

	a {
		color: inherit;
	}

	.swiper {

		overflow: initial !important;

		&-slide {
			height: initial !important;
		}
	
	}

	picture {

		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 232px;
		height: 156px;
		margin: 0 auto;

		img {
			position: relative;
			z-index: 5;
			transition: all .3s ease-in-out;
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
		}
		&:before {
			top: 50%;
			left: 50%;
			width: 134px;
			height: 131px;
			background: url('../svg/bg-thumbs.svg') no-repeat center center;
			transform: translate(-50%,-50%);
			z-index: 1;

		}
		&:after {
			width: 83px;
			height: 93px;
			background: url('../svg/firula-thumb.svg') no-repeat center center;
			transition: all .3s ease-in-out;
			z-index: 1;

		}
	
	}

	.slider-item {

		display: flex;
		flex-direction: column;
		padding: 32px 24px;
		background: #FEFEFE;

		div {
			flex: 1;
		}

		&:hover picture img {
			transform: rotate(-6deg)
		}

		&.desmatamento {

			picture:after {
				right: 31px;
  				top: -19px;
  				transform: rotate(156deg);
			}

			img {
				width: 158px;
			}
			
			&:hover picture:after {
				transform: rotate(171deg);
			}

		}

		&.gases {

			picture:after {
				left: 4px;
				top: 41px;
				transform: rotate(0);
			}

			img {
				width: 146px;
			}

			&:hover picture:after {
				transform: scale(.9) translateX(5px);
			}

		}

		&.agua {

			picture {

				padding-left: 20px;

				&:after {
					right: 31px;
	  				top: -19px;
	  				transform: rotate(156deg);
				}
			
			}

			img {
				width: 162px;
			}
			
			&:hover picture:after {
				transform: rotate(148deg) scale(.85) translateX(8px);
			}
		
		}

		&.biodiversidade {

			picture {

				padding-right: 15px;

				&:after {
					left: 3px;
  					top: 31px;
					transform: rotate(18deg);
				}
			
			}

			img {
				width: 162px;
			}
	
			&:hover picture {

				img {
					transform: rotate(8deg)
				}

				&:after {
					transform: rotate(5deg);
				}
				
			}

		}

	}
	
}

@media( min-width: 1280px ){

	#carne-lnd .slider-squares > div {
		display: flex;
		gap: 16px;
	}

}

@media( min-width: 501px ){

	#carne-lnd .slider-squares {

		.slider-item {
			width: 280px;
			min-width: 280px;
			max-width: 280px;
			border: 1px solid #E3E4E8;
			
		}

	}

}

@media( max-width: 500px ){

	#carne-lnd .slider-squares {

		padding-bottom: 33px;
		border: 1px solid #E3E4E8;
		overflow: hidden;

		.slider-dots {
			margin-top: 0;
		}

	}

}