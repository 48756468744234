#carne-lnd section.compartilhe {

	position: relative;
	display: flex;
	align-items: center;
	height: 354px;
    margin-top: 52px;
	color: #FFF;
	background: #009EE2;

	> div {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		max-width: 477px;
		margin-left: calc( 50% + 139px );
	}

	h2 {
		margin: 0;
		text-align: left;
		font-family: 'Archivo';
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}

	em {
		font-size: 24px;
		font-style: normal;
		line-height: 34px;
	}
    
    &:before {
    	content: '';
    	width: 734px;
    	height: 100%;
    	display: block;
		background: url('../images/banner-share.png') no-repeat right center;
		background-size: contain;
    }
	
}

@media( min-width: 768px ) AND ( max-width: 1010px ){

	#carne-lnd section.compartilhe {
		
		height: 294px;
		
		> div {
			width: 100%;
			max-width: initial;
			padding-right: 24px;
			margin-left: calc(50% - 60px);
		}

		&:before {
			top: 50%;
			right: calc( 50% + 92px );
			width: 445px;
			height: 322px;
			background-image: url('../images/banner-share-mobile.png');
			transform: translateY(-50%);
		}

	}

}

@media( min-width: 768px ) AND ( max-width: 875px ){

	#carne-lnd section.compartilhe {

		> div {
			margin-left: calc(50% - 120px);
		}

		&:before {
			right: calc(50% + 144px);
			width: 345px;
			height: 322px;
		}

	}

}

@media( min-width: 768px ) AND ( max-width: 840px ){

	#carne-lnd section.compartilhe {

		> div {
			margin-left: 302px;
		}

		&:before {
			right: initial;
			left: -70px;
		}

	}

}

@media( min-width: 768px ){
	#carne-lnd section.compartilhe:before {
    	position: absolute;
    	top: 0;
    	right: calc( 50% - 67px );
	}
}

@media( max-width: 767px ){

	#carne-lnd section.compartilhe {

		flex-direction: column;
		align-items: center;
		height: auto;
		padding: 16px 24px 32px;

		> div {
			margin: -17px 0 0;
		}

		&:before {
			position: relative;
			width: 486px;
			height: 322px;
			background-image: url('../images/banner-share-mobile.png');
			transform: translateX(-40px);
		}

	}

}
