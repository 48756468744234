.menu-toggle {

	display: block;
	position: relative;
	width: 24px;
	height: 24px;
	padding: 0;
	border-radius: 0;
	border: 0;
	cursor: pointer;
	z-index: 50;
	transition: all 400ms;
	background: none;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		transition: all 400ms;
	}

	> span {
		
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate( -50%, -50% );
		width: 23px;
		height: 20px;

		span {

			display: block;
			position: absolute;
			width: 100%;
			height: 2px;
			opacity: 1;
			left: 0%;
			transform: rotate(0deg);
			background: #333333;
			border-radius: 1px;

			&:nth-child(1),
			&:nth-child(3){
				transition: 
					transform .3s ease, 
					left .3s ease,
					top .3s ease .3s,
					background-color 300ms
				;
			}
			&:nth-child(1) {
				top: 0px;
			}
			&:nth-child(2){
				top: 9px;
				transition: width .4s ease, opacity .4s ease, background-color .3s;
			}
			&:nth-child(3) {
				top: 18px;
			}
		
		}

	}

	&.color--2 > span span {
		background: #ffffff;
	}

}

.menu-opened .menu-toggle {
	
	> span {

		span {

			width: 110%;

			&:nth-child(2) {
				width: 0;
				opacity: 0;
			}
			&:nth-child(1),
			&:nth-child(3){
				top: 50%;
				left: 0;
				transition: 
					top .3s ease, 
					transform .3s ease .3s, 
					left .3s ease .3s,
					background-color .3s
				;
			}
			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
			}

		}

	}

}
