#carne-lnd header.header-section {

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	svg {
		margin-bottom: 16px;
	}

	h2 {
		margin: 0;
	}

	em {
		display: block;
		color: #6D7280;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 27px;
	}

}

@media( max-width: 768px ){

	#carne-lnd header.header-section {
	
		h2 {
			text-align: center;
		}
		
		br {
			display: none;
		}

	}

}

@media( max-width: 640px ){

	#carne-lnd header.header-section {

		gap: 32px;
	
		h2 {
			font-size: 32px;
			line-height: 32px;
		}

		em {
			font-size: 16px;
			line-height: 24px;
		}

	}

}