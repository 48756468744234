#carne-lnd div.cadastre-se {

	position: relative;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	max-width: 1168px;
	height: 377px;
	padding-left: 5.4794520548%;
	margin-top: 88px;
	color: #FEFEFE;
	background: url('../svg/bg-ta-na-mesa.svg') no-repeat center center;
	background-size: contain;

	div {
		max-width: 508px;
	}

	h2 {
		color: #FEFEFE;
		font-family: 'Archivo';
		font-size: 32px;
		font-weight: 700;
		line-height: 32px;
		text-align: left;
	}

	button {

		margin-top: 24px;

		span {
			font-size: 20px;
		}
	
	}

	.graph {

		position: relative;
		width: 47.9452054795%;

		span {

			padding-top: 86.6845397676%;

			&:before {
				background-image: url('../images/ta-na-mesa.png');
			}
		
		}

	}

}

@media( max-width: 1210px ){
	#carne-lnd div.cadastre-se {
		width: auto;
		margin-left: 24px;
		margin-right: 24px;
	}
}

@media( max-width: 978px ){
	#carne-lnd div.cadastre-se {
		background-size: cover;
		div {
			max-width: 58%;
		}
	}
}

@media( max-width: 768px ){
	#carne-lnd div.cadastre-se {
	    margin-left: 0;
	    margin-right: 0;
  	}
}

@media( max-width: 640px ){
	
	#carne-lnd div.cadastre-se {

		flex-direction: column;
		height: auto;
		padding: 0 16px;
		margin-top: 38px;
		background: none;

		h2 {
			text-align: center;
		}


  		button {
  			width: 100%;
  			max-width: 280px;
  			margin: 24px auto 0;
  		}

  		.graph,
  		div {
  			position: relative;
  			z-index: 5;
  		}

		div {
    		max-width: 320px;
  		}

  		.graph {
  			width: 280px;
  		}

  		&:before {
  			content: '';
  			display: block;
  			position: absolute;
  			top: 76px;
  			right: 0;
  			bottom: 24px;
  			left: 0;
  			background: #E5007E;
  			z-index: 1;
  		}

	}
	
}

