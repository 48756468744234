#carne-lnd .banner-numeros {
	
	position: relative;
	display: flex;
	align-items: center;
	max-width: 1168px;
	height: 371px;
	padding: 38px 0;
	margin: 50px auto 0;
	color: #FEFEFE;
	background: url('../images/bg-slider.png') no-repeat center center;
	background-size: cover;

	> div.wrapper {
		position: relative;
		display: block;
		width: 100%;
		margin-top: 10px;
	}

	h2 {
		margin: 0;
		font-family: 'Archivo';
		font-size: 40px;
		font-weight: 700;
		line-height: 40px;
		text-align: center;
	}

	cite {

		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
		font-style: normal;

		a {
			color: inherit;
		}
	
	}

	.slider-item {
		display: flex !important;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		padding: 0 84px;
	}
	
	.swiper-slide {
        
        opacity: 0!important;
        transition-duration: .8s !important;

        &.swiper-slide-active {
            opacity: 1 !important;
        }

    }

	.slider-arrows {
		position: absolute;
		top: 40px;
		right: 84px;
		display: flex;
		align-items: center;
		gap: 16px;
	}

	.slider-dots {
		position: absolute;
		left: 50%;
		bottom: 38px;
		transform: translateX(-50%);
	}

}

@media( max-width: 1216px ){
	#carne-lnd .banner-numeros {
		margin: 50px 24px 0;
	}
}

@media( max-width: 980px ){

	#carne-lnd .banner-numeros {

		h2 {
			font-size: 32px;
			line-height: 32px;
		}

		.slider-item {
			padding: 0 24px;
		}

	}

}

@media( max-width: 604px ){
	
	#carne-lnd .banner-numeros {

		height: 441px;
		padding: 38px 0 92px;
		margin: 50px 21px 0;
		background-image: url("../images/bg-slider-mobile.png");
		clip-path: polygon(0 0, 100% 10px, 100% calc( 100% - 10px ), 0% 100%);

		.slider-arrows {
			display: none !important;
		}
	
	}
	
}

@media( max-width: 460px ){

	#carne-lnd .banner-numeros {
	
		h2 {
	    	font-size: 28px;
	    	line-height: 30px;
	    }
	
	    .slider-item {
	    	padding: 0 20px;
	    	gap: 14px;
	    }

  	}

}