// Structure
@import 'structure/normalize';
@import 'structure/vars';
@import 'structure/base';
@import 'structure/animations';
@import 'structure/menu-toggle';
@import 'structure/header';
@import 'structure/footer';

// Components
@import 'components/loader-mask';
@import 'components/button';
@import 'components/slick';
@import 'components/slider';
@import 'components/networks';
@import 'components/header-section';
@import 'components/hero';
@import 'components/banner-numeros';
@import 'components/por-que-rastrear';
@import 'components/list-squares';
@import 'components/cadastre-se';
@import 'components/headed-squares';
@import 'components/banner-chevron';
@import 'components/slider-squares';
@import 'components/como-questionar';
@import 'components/impactos';
@import 'components/de-olho';
@import 'components/compartilhe';
@import 'components/biblioteca';
// @import 'components/inicio';
// @import 'components/ta-na-mesa';
// @import 'components/como-ler-os-carnes';
@import 'components/quem-somos';
@import 'components/newsletter';
@import 'components/modal-share';
@import 'components/modal-enquete';

// Internet explorer
//@import 'structure/ie';

@media( min-width: 733px ){
    body .just-mobile {
        display: none !important;
    }
}
@media( max-width: 732px ){
    body .hide-mobile {
        display: none !important;
    }
}