@media( max-width: 640px ){

	#carne-lnd section#impactos {

		.headed-squares {
			margin-top: 0;
		}

	}

}

