*,
*:before,
*:after {
    box-sizing: border-box;
}

// Prevent scroll top bug
html.locked-scroll.init-scroll {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
}

html,
body {
    background: #FFF !important;
    scroll-padding: 80px 0 0 0;
    scroll-behavior: smooth;
}

#carne-lnd {

    position: relative;
    width: 100%;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    z-index: 99;
    color: #333333;

    *, 
    *:before, 
    *:after {
        box-sizing: border-box;
    }

    .center {
        display: block;
        width: 100%;
        max-width: 1280px;
        padding-left: 56px;
        padding-right: 56px;
        margin: 0 auto;
    }

    h1, h2, h3, h4, h5, h6, em {
        font-style: normal;
        font-weight: normal;
        font-size: 100%;
    }

    h2 {
        margin: 0 0 24px;
        font-family: 'Archivo';
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
    }

    h3 {
        margin-top: 56px;
        font-family: 'Archivo';
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    mark.mark-highlight {

        padding-left: 5px;
        padding-right: 5px;
        font-style: normal;
        background-color: transparent;
        background-size: 200%;
        background-position: 0;
        background-image: linear-gradient(90deg, rgba(255, 231, 73, 0) 50%, rgb(255, 231, 73) 0);
        transition: 
            background-position 1.6s,
            color 2s
        ;

        &.animated {
            background-position: -100%;
        }

    }

    p {

        margin: 0 0 29px;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: #6D7280;

        &:last-of-type {
            margin-bottom: 0;
        }

    }

    em {

        font-style: normal;

        &.pink {
            color: #E5007E;
            font-weight: 500;
        }
    
    }

    cite {
        display: block;
        font-family: 'Inter';
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        line-height: 16px;
    }

    ul {

        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;

        &.default {

            display: grid;
            grid-template-columns: repeat(3, auto);
            margin-bottom: -24px;

            li {

                position: relative;
                display: flex;
                align-items: center;
                padding: 25px 25px 25px 39px;
                font-family: 'Archivo';
                font-size: 24px;
                font-weight: 400;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    background: #E5007E;
                    transform: translateY(-50%);
                }

            }
        
        }

        &.with-skuls {

            flex-direction: column;
            gap: 48px;
            max-width: 480px;

            li {

                display: flex;
                align-items: center;
                gap: 16px;
                font-family: 'Inter';
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;

                &:before {
                    content: '';
                    display: block;
                    width: 64px;
                    min-width: 64px;
                    height: 64px;
                    border-radius: 51%;
                    background: #E5007E url('../svg/skul.svg') no-repeat center center;
                }

            }

        }

    }

    i {

        display: block;
        width: 30px;
        height: 30px;
        background: no-repeat center center;
        background-size: contain;

        &.coracao {
            background-image: url('../images/icon-coracao.svg');
        }
    
    }

    div.sides {

        @extend .center;
        display: flex;
        align-items: center;
        gap: 42px;
        padding-top: 56px;
        padding-bottom: 56px;

        h2,
        p {
            letter-spacing: -0.02em;
        }

        h2 {
            text-align: left;
        }

        .texts {
            flex: 1;
        }

        .image-wrapper {
            
            width: 100%;
            max-width: 464px;

            picture {

                display: block;
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 59.9137931034%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            
            }
        
        }

        &.img-right {
            flex-direction: row-reverse;
        }
        
    }
        
    .slider-arrows {

        position: relative;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-bottom: 32px;
    
        button {

            position: relative;
            left: initial;
            right: initial;
            width: 40px;
            height: 40px;
            border: 2px solid transparent;
            background: #EB609E;
            cursor: pointer;
            overflow: hidden;
            text-indent: -9999px;
            transition: all .4s ease-in-out;
        
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: url('../svg/alider-arrow.svg') no-repeat center center;
                transition: all .4s ease-in-out;

            }

            &:hover {
                background-color: #8C004C;                
            }

            &.slider-prev {
                transform: scaleX(-1)
            }

            &.swiper-button-disabled {

                background: transparent;
                border-color: #EB609E;
                opacity: 0.4;
                cursor: default;

                &:before {
                    filter: invert(13%) sepia(34%) saturate(6717%) hue-rotate(310deg) brightness(94%) contrast(115%);
                }
                
            }
        
        }

        &.color--2 {
            
            button {

                border-color: #FFF;
                background: #FFF;

                &:before {
                    filter: brightness(0) invert(20%) sepia(81%) saturate(951%) hue-rotate(163deg) brightness(92%) contrast(103%);
                }

                &:not(.swiper-button-disabled):hover {
                    background-color: #81CFF4;
                    border-color: #81CFF4;
                }

                &.swiper-button-disabled {

                    background: transparent;
                    
                    &:before {
                        filter: none;
                    }
                
                }
        
            }
        
        }
    
    }

    .slider-dots {

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 56px;

        .swiper-pagination-bullet {

            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #E3E4E8;
            opacity: 1;
            transition: all .4s ease-in-out;

            &-active {
                width: 29px;
                background-color: #009EE2;
            }
        
        }

        &.color--2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: #E5007E;
        }

    }

    .slider-wrapper {

        position: relative;
        display: block;
        width: 100%;
        margin-top: 32px;

        .swiper {
            overflow: initial;
        }
    
    }

    .text-border-left {

        position: relative;
        padding-left: 22px;
        margin-top: 32px;

        em {
            color: #6D7280;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 6px;
            background: #009EE2;
            clip-path: polygon(0 0, 100% 3px, calc( 100% - 1px ) calc( 100% - 6px ), 0% calc( 100% - 2px ) );

        }

    }

    .list-images {
        
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 24px;

        .list-item {

            width: calc( 33.3333%  - 11px );

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                padding: 16px;
                border: 1px solid #E3E4E8;
                background: #FEFEFE;
                box-shadow: 0px 0px 4px 0px rgba( 0, 0, 0, 0.15 );   
            }

            picture {
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-top: 44.2446043165%;
                background: #dddddd;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            em {
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
            }
        
        }

    }

    section {
        
        position: relative;
        padding-top: 28px;
        padding-bottom: 28px;

        &:not(.full){
            @extend .center;
        }

        &#inicio {
            padding-top: 0;
        }

        &.topbar:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 100%;
            max-width: 712px;
            height: 2px;
            background: #E3E4E8;
            transform: translateX(-50%);
        }
    
    }

    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }
    
}

.graph {

    position: absolute;
    display: block;
    
    span {

        display: block;
        position: relative;
        width: 100%;
        height: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: no-repeat center center;
            background-size: contain;
        }            
    
    }

}

@media( max-width: 980px ){
    
    #carne-lnd div.sides {

        &,
        &.img-right {

            align-items: flex-start;
            flex-direction: column;
        
        }
    
    }

}

@media( max-width: 768px ){

    #carne-lnd {

        .center {
            padding-left: 24px;
            padding-right: 24px;
        }


        h2 {

            br {
                display: none;
            }

        }

        h3 {
            font-size: 20px;
            line-height: 30px;
        }

        p {
            font-size: 18px;
            line-height: 26px;
        }

        ul {

            &.default {
                grid-template-columns: repeat(2, auto);
            }

        }

        .list-images .list-item {
            width: calc( 50% - 8px );
        }

    }

}

@media( max-width: 767px ){

    #carne-lnd {
        
        h2 {
            text-align: left;
        }
        
        .text-border-left {

            position: relative;
            overflow: hidden;
            
            em {
                font-size: 18px;
                line-height: 27px;
            }
        
        }

    }

}

@media( max-width: 610px ){

    #carne-lnd {

        ul.default {
            grid-template-columns: repeat(1, auto);
        }

        .list-images .list-item {
            width: 100%;
        }
        
    }

}

@media( max-width: 540px ){

    #carne-lnd {

        p {
            margin: 0 0 23px;
        }

    }
    
}

@media( max-width: 480px ){

    #carne-lnd {

        h3 {
            margin-top: 24px;
        }

        ul {

            &.default li {
                padding: 18px 25px 18px 39px;
                font-size: 20px;
            }

            &.with-skuls {

                gap: 24px;

                li {

                    font-size: 16px;
                    line-height: 150%;

                    &:before {
                        width: 48px;
                        min-width: 48px;
                        height: 48px;
                        background-size: 21px auto;
                    }

                }
            
            }

        }

        div.list-images {

            .slick-list {
                overflow: initial;
            }

            .list-item {
                padding: 0 6px;
            }

            .slick-dots {

                display: flex;
                justify-content: center;
                gap: 7px;
                margin-top: 24px;

                li,
                button {
                    height: 8px;
                }

                button {
                    width: 8px;
                    padding: 0;
                    border: 0;
                    border-radius: 4px;
                    background: #D9D9D9;
                    overflow: hidden;
                    text-indent: -99999px;
                    overflow: hidden;
                    transition: all .4s ease-in-out;
                }

                li.slick-active button {
                    background-color: #009EE2;                    
                }
            
            }
        
        }

        section {

            padding-top: 22px;
            padding-bottom: 22px;

            &.topbar:before {
                width: calc( 100% - 48px );
            }

        }

        div.sides {

            gap: 32px;
            padding-top: 32px;
            padding-bottom: 32px;
        
            a.button {

                &,
                span {
                    width: 100%;
                    justify-content: center;
                }

            }

        }
    
    }

}

@keyframes progress {
    from { right: 100%; }
    to { right: 0; }
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clear:before,
.clear:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden; 
}
.clear:after{ 
    clear: both;  
}
.clear { 
    zoom: 1; 
}