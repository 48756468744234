#modal-newsletter {

	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	padding: 0 24px;
	background: rgba( 0, 0, 0, 0.35 );
	z-index: 9999999;
	overflow-x: hidden;
	overflow-y: auto;
	opacity: 0;
	transform: translateX(-200dvw);
	transition: 
		opacity .5s ease-in-out,
		transform 1ms ease .5s
	;

	h3 {
	    margin: 0;
		text-align: center;
		font-family: 'Archivo';
	    font-size: 24px;
	    line-height: 34px;
	    font-weight: 400;
	    color: #595959;
	}

	button {
		display: block;
		margin: 0 auto;
		border: 0;
		font-family: 'Inter';
		text-decoration: underline;
		background: none;
		color: #666666;
	    font-size: 20px;
	    cursor: pointer;
	}

	> div {

		display: block;
		width: 100%;
		min-height: 100dvh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 24px 0;

		> div {
			width: 100%;
			max-width: 609px;
			padding: 34px 20px 20px;
			border-radius: 4px;
			background: #f0f1f2;
			box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.25 );
		}

	}

	iframe {
		width: 100%; 
		height: 370px;
	}

	&.opened {
		transition: opacity .5s ease-in-out;
		opacity: 1;
		transform: translateX(0);
	}
	
}

@media( max-width: 540px ){

	#modal-newsletter {

		padding: 0px 16px;

		> div {
			padding: 16px 0;
		}

		h3 {
			font-size: 18px;
			line-height: 24px;
		}
		button {
			margin-top: 8px;
			font-size: 16px;
		}

	}

}

@media( max-width: 440px ){
	#modal-newsletter iframe {
  		height: 386px;
	}
}

@media( max-width: 360px ){
	#modal-newsletter iframe {
  		height: 420px;
	}
}	
