#carne-lnd div.modal-enquete {

	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	max-width: initial;
	height: 100dvh;
	overflow-x: hidden;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.20);
	backdrop-filter: blur(7.5px);
	color: #FEFEFE;
	z-index: 9999;
	opacity: 0;
	transform:translateX(-200dvw);
	transition: 
		opacity .5s ease-in-out,
		transform 1ms ease .5s
	;

	h2 {

		flex: 1;
		text-align: left;
		font-family: 'Archivo';
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;

		mark {
			color: #004562;
		}

	}

	em {
		font-size: 24px;
		font-style: normal;
		line-height: 34px;
	}

	> div {

		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 100dvh;
		padding: 0 56px;

		> div {
			position: relative;
			max-width: 1008px;
			background: rgba(229, 0, 126, 0.8);
			padding: 125px 69px 118px 74px;
			clip-path: polygon( 0 38px, 100% 0, 100% 100%, 0 calc( 100% - 38px ) );
		}

	}

	.close {
		position: absolute;
		top: 13px;
		right: 17px;
		padding: 0;
		background: 0;
		border: 0;
		cursor: pointer;
	}

	.quizz {

		display: flex;
		align-items: center;
		gap: 55px;
		transition: opacity .3s ease-in-out;

		form {
			width: 286px;
		}

		&.hide {
			position: absolute;
			opacity: 0;
		}
	
	}

	.result {

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;

		h2 {

			margin: 0;

			mark {
				padding-left: 20px;
				padding-right: 20px;
				margin-left: -20px;
				color: #FFF;
				background-image: linear-gradient(90deg, rgba(0, 69, 98, 0) 50%, rgb(0, 69, 98) 0);
			}
		
		}

		a,
		button {
			&,
			span {
				width: 286px;
				justify-content: center;
			}
		}

		&.style--1 h2 {
			font-size: 56px;
			margin-bottom: 8px;
		}

		&.show {
			position: relative !important;
			transition: opacity .4s ease-in-out;
			transform: translateX(0) !important;
			opacity: 1 !important;
		}

	}

	.item-radio {

		i {

			&,
			&:before {
				border-radius: 51%;
			}

			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			border: 3px solid #FFEC00;
			width: 24px;
			height: 24px;
			transition: all .3s ease-in-out;

			&:before {
				display: block;
				content: '';
				width: 14px;
				height: 14px;
				background: #FFEC00;
				opacity: 0;
				transition: all .3s ease-in-out;
			}
		
		}

		label {

			display: inline-flex;
			align-items: center;
			gap: 16px;
			height: 56px;
			padding: 0 16px;
			border-radius: 28px;
			color: #FFEC00;
			transition: all .3s ease-in-out;

			&:hover {

				background-color: #F9F9FC;
				color: #004562;

				i {

					border-color: #004562;

					&:before {
						background-color: #004562;
					}
				
				}
			
			}
		
		}

		input {

			position: fixed;
			right: 5000dvw;

			&:checked + label i:before {
				opacity: 1;
			}

		}

	}

	form button {

		cursor: pointer;
		margin-top: 8px;
		
		&,
		span {
			width: 100%;
			justify-content: center;
		}
	
	}

	fieldset {
		display: flex;
		flex-direction: column;
		gap: 8px;
		border: 0;
		padding: 0;
		margin: 0;
	}

	&.opened {
		transition: opacity 1s ease-in-out;
		opacity: 1;
		transform:translateX(0);
	}

}

@media( max-width: 1024px ){

	#carne-lnd div.modal-enquete {

		> div {

			padding: 0 24px;

			> div {
				max-width: 710px;
				padding: 65px 24px 68px 24px;
			}
		
		}

		h2 {

			font-size: 32px;
			line-height: 36px;

			mark {
				padding-left: 8px;
				padding-right: 8px;
				margin-left: 8px;
			}
		
		}

		.result.style--1 h2 {
  			font-size: 40px;
  			line-height: 40px;
  		}
  

	}

}

@media( max-width: 680px ){

	#carne-lnd div.modal-enquete .quizz {

		flex-direction: column;
		gap: 20px;
		padding: 65px 44px 68px 44px;

		h2 mark {
			margin-left: 0;
		}

	}
	
}

@media( max-width: 600px ){

	#carne-lnd div.modal-enquete {

		padding: 0;
		background: #E5007E;

		> div {

			flex-direction: column;
			padding: 0;

			> div {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 82px 24px 24px;
				background: transparent;
				clip-path: none;
			}
			
		}

		.quizz,
		.result {
			padding: 0;
		}

	}
	
}


@media( max-width: 480px ){

	#carne-lnd div.modal-enquete {

		.quizz {

			h2 {
				margin: 0;
				text-align: center;
			}

			form {
				width: 100%;
			}
		
		}

	}
	
}
