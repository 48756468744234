#carne-lnd .banner-chevron {

	margin-top: 96px;

	h2 {
		margin: 0;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: 42px;
		text-align: left;
	}

	cite {

		font-family: 'Archivo';
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px;

		a {
			color: inherit;
		}

	}

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		background: #E5007E;
		padding: 62px 34px 97px 36px;
		clip-path: polygon(0 8.4%, 100% 0, 100% 100%, 0 91.6%);
		color: #FEFEFE;
	}

	em {
		position: relative;
		display: block;
		padding: 31px 40px;
		margin: -105px 68px 0;
		text-align: center;
		font-family: 'Archivo';
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
		background: #FFEC00;
		z-index: 5;
		clip-path: polygon(0 25px, 100% 0, 100% 100%, 0 calc(100% - 7px));
	}

}

@media( max-width: 1120px ){
	#carne-lnd .banner-chevron em {
		margin-top: -90px;
		font-size: 32px;
		line-height: 32px;
	}
}

@media( max-width: 640px ){

	#carne-lnd .banner-chevron {
		
		margin-top: 36px;

		cite {
			font-size: 16px;
			line-height: 16px;
		}
		
		h2 {
			font-size: 18px;
			line-height: 27px;
		}

		header {
			align-items: flex-start;
			gap: 16px;
			padding: 50px 13px 28px;
			clip-path: polygon(0 37px, 100% 0, 100% 100%, 0 91.6%);
		}

		em {
			padding: 17px 20px;
			margin: -22px -8px 0;
			text-align: left;
			clip-path: polygon(0 0, 100% 18px, 100% calc( 100% - 4px ), 0 100%);
		}
		
	}

}
